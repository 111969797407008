import React, {useState, useEffect} from 'react';
import {Trans, useTranslation, Link} from 'gatsby-plugin-react-i18next';
import FilterMenu from "./filter-menu";
import iconFilters from '../dealers/images/icon-filters.svg';
import pulldownArrowWhite from "../dealers/images/filter-menu-down-white.svg";
import pulldownArrow from "../dealers/images/filter-menu-down.svg";

const LocksOverview = ({lockList}) => {
  const [lockTypeList, setLockTypeList] = useState([
    { id: 1, type: "All Lock Models", typeSimple: "all" },
    { id: 2, type: "Wireless Locks", typeSimple: "wireless" },
    { id: 3, type: "Hardwired Locks", typeSimple: "hardwired" },
    { id: 4, type: "Mechanical Locks", typeSimple: "mechanical" },
  ]) ;
  const [selectedLockType, setSelectedLockType] = useState(1);
  const [selectedLockLabel, setSelectedLockLabel] = useState(lockTypeList[0].type);
  const [checkedProductsClass, setCheckedProductsClass] = useState('all');
  const [menuOpen, setMenuOpen] = useState(false);
  const handleProductMenuOpen = () => {
    setMenuOpen(!menuOpen);
  }
  const windowClickHandler = (e) => {
    let typeMenu = document.getElementsByClassName('locks-filter-menus')[0];
    if (!typeMenu.contains(e.target)) {
      setMenuOpen(false);
    }
  }
  const handleRadioClick = (id) => {
    setSelectedLockType(id);
    setMenuOpen(false);
  }
  //use this to update
  useEffect(() => {
    for (let i = 0; i < lockTypeList.length; i++) {
      if (lockTypeList[i].id === selectedLockType) {
        setCheckedProductsClass(prevState => lockTypeList[i].typeSimple);
        setSelectedLockLabel(prevState => lockTypeList[i].type);
      }
    }
  }, [selectedLockType]);
  useEffect(() => {
    let ele = document.getElementsByName('lock-type');
    ele[0].checked = true;

    window.addEventListener('click', windowClickHandler);
    return () => {
      window.removeEventListener('click', windowClickHandler);
    };
  }, []);
  return (
      <>
        <nav className={'locks-filter-mobile'}>
          <div className={'filter-icon'}>
            <img src={iconFilters} alt="filters" width={'16'} height={'15'}/>
            &nbsp;Filters
          </div>
          <div className={'dropdown locks-filter-menus'}>
            <div className={menuOpen   ? 'dropdown-label open' : 'dropdown-label'} onClick={handleProductMenuOpen}>
              <span>{selectedLockLabel}</span>
              <img src={menuOpen ? pulldownArrowWhite : pulldownArrow} alt="arrow" width={'12'} height={'7'}/>
            </div>
            <div className={menuOpen ? 'dropdown-menu open' : 'dropdown-menu'}>
          <FilterMenu
              items={lockTypeList}
              handleCheckedState={handleRadioClick}
          />
            </div>
          </div>
        </nav>
        <nav className={'locks-filter-desktop'}>
          <div className={'filter-menu-container '+checkedProductsClass}>
            <FilterMenu
                items={lockTypeList}
                handleCheckedState={handleRadioClick}
            />
          </div>
        </nav>
        <div className={"lock-grid "+checkedProductsClass}>
          {lockList.filter((lock, index) => index !== 2 && index !== 7).map(({lock_name, lock_type, lock_width, lock_height, lock_alt, lock_image, lock_thumb, lock_url}, index) => {
            return (
                <div className={'thumbnail '+lock_type+' btn-industry'}>
                <Link to={'/products'+lock_url}>
                  <img
                      key={index}
                      src={lock_thumb}
                      className={'lock-thumb'}
                      width={lock_width}
                      height={lock_height}
                      data-locktype={lock_type}
                      alt={lock_alt} />
                  <p>
                    <span dangerouslySetInnerHTML={{__html: lock_name}} />
                  </p>
                </Link>
                </div>

            );
          })}
        </div>
      </>

  )
};

export default LocksOverview;