import React from "react";
import {Trans, useTranslation, Link} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import BookConsultation from "../../components/pages/BookConsultation";
import CustomLocalizedLink from "../../components/locales/custom-localized-link";
import LocksOverview from "../../components/products/LocksOverview";
import Banner from "../../components/sections/banner";
import ThreeButtonsLocks from "../../components/pages/ThreeButtonsLocks";

import secureLocker from '../../images/home/secure-lockers.png';
import secureCabinets from '../../images/home/secure-cabinets.png';
import securePedestals from '../../images/home/secure-pedestals.png';
import thumbCurve from '../../images/home/thumb-curve.png';
import thumbAspire from '../../images/home/thumb-aspire.png';
import thumbVersa from '../../images/home/thumb-versa.png';
import thumbOrbit from '../../images/home/thumb-orbit.png';
import thumbOrbitVertical from '../../images/home/thumb-orbit-vertical.png';
import thumbPivot from '../../images/home/thumb-pivot.png';
import thumbCleo from '../../images/locks/thumb-cleo.png';
import thumbCleoNoKey from '../../images/home/thumb-cleo.png';
import thumbMech from '../../images/locks/thumb-mech.png';


const IndexPage = () => {

  const {t} = useTranslation();
  const lockList = [
    {
      "lock_name": "Curve<sup>&trade;</sup>",
      "lock_type": "wireless",
      "lock_image": "DL22-curve-smart-600x497.png",
      "lock_width": "163",
      "lock_height": "93",
      "lock_alt": "Curve Smart Lock",
      "lock_thumb": thumbCurve,
      "lock_url": "/smart-locks/curve/"
    },
    {
      "lock_name": "Aspire<sup>&trade;</sup>",
      "lock_type": "wireless",
      "lock_image": "DL22-net-aspire-600x497.png",
      "lock_width": "197",
      "lock_height": "103",
      "lock_alt": "Aspire Smart Lock",
      "lock_thumb": thumbAspire,
      "lock_url": "/smart-locks/aspire/"

    },
    {
      "lock_name": "Orbit<sup>&trade;</sup>",
      "lock_type": "wireless",
      "lock_image": "DL22-net-orbit-600x497.png",
      "lock_width": "120",
      "lock_height": "148",
      "lock_alt": "Orbit Smart Lock",
      "lock_thumb": thumbOrbit,
      "lock_url": "/smart-locks/orbit/"
    },
    {
      "lock_name": "Orbit<sup>&trade;</sup>",
      "lock_type": "wireless",
      "lock_image": "DL22-net-orbit-600x497.png",
      "lock_width": "98",
      "lock_height": "102",
      "lock_alt": "Orbit Smart Lock",
      "lock_thumb": thumbOrbitVertical,
      "lock_url": "/smart-locks/orbit/"
    },
    {
      "lock_name": "Versa<sup>&trade;</sup>",
      "lock_type": "wireless",
      "lock_image": "DL22-net-versa-600x497.png",
      "lock_width": "187",
      "lock_height": "113",
      "lock_alt": "Versa Smart Lock",
      "lock_thumb": thumbVersa,
      "lock_url": "/smart-locks/versa/"
    },
    {
      "lock_name": "Pivot<sup>&trade;</sup>",
      "lock_type": "hardwired",
      "lock_image": "pivot-system.png",
      "lock_width": "149",
      "lock_height": "110",
      "lock_alt": "Pivot with kiosk",
      "lock_thumb": thumbPivot,
      "lock_url": "/smart-locks/pivot/"
    },
    {
      "lock_name": "Cleo<sup>&trade;</sup>",
      "lock_type": "mechanical",
      "lock_image": "thumb-cleo.png",
      "lock_width": "186",
      "lock_height": "119",
      "lock_alt": "Cleo",
      "lock_thumb": thumbCleo,
      "lock_url": "/mechanical-locks/cleo/"
    },
    {
      "lock_name": "Cleo No Key<sup>&trade;</sup>",
      "lock_type": "mechanical",
      "lock_image": "thumb-cleo.png",
      "lock_width": "172",
      "lock_height": "99",
      "lock_alt": "Cleo",
      "lock_thumb": thumbCleoNoKey,
      "lock_url": "/mechanical-locks/cleo/"
    },
    {
      "lock_name": "Mech<sup>&trade;</sup>",
      "lock_type": "mechanical",
      "lock_image": "thumb-mech.png",
      "lock_width": "186",
      "lock_height": "119",
      "lock_alt": "Mech",
      "lock_thumb": thumbMech,
      "lock_url": "/mechanical-locks/mech/"
    }
  ];

  return (
      <Layout>
        <Seo
            title={t('products_locks')}
            description={t('des_products_locks')}
        />
        <div className="locks">
          <section className="hero">
            <div class="container">
              <h1>
                <Trans>
                  digilock_purpose
                </Trans>
              </h1>
              <StaticImage
                  src="../../images/locks/locks-overview-hero.png"
                  quality={100}
                  width={773}
                  height={264}
                  formats={["auto", "webp", "avif"]}
                  loading={'lazy'}
                  alt="Digilock family"
              />
              <p dangerouslySetInnerHTML={{__html: t('digilock_purpose_copy')}}/>
              <ThreeButtonsLocks />
              {/*<nav>*/}
              {/*  <div class="sl-buttons">*/}
              {/*    <CustomLocalizedLink*/}
              {/*        label={t('help_me_choose')}*/}
              {/*        goto={'/quiz/'}*/}
              {/*        cls={'btn btn--grey'}/>*/}
              {/*  </div>*/}
              {/*  <BookConsultation/>*/}
              {/*</nav>*/}
            </div>
          </section>
          <section class="all-lock-models">
            <div class="container">
              <LocksOverview lockList={lockList}/>
            </div>
          </section>
          <section className="anytime-anywhere-overview">
            <div className="container">
              <div className="grid">
                <div>
                  <h2 dangerouslySetInnerHTML={{__html:t('anytime_headline')}}/>
                  <p dangerouslySetInnerHTML={{__html:t('anytime_copy')}} />
                  <StaticImage
                      className={'dl-tab'}
                      src="../../images/home/digilink-tablet.png"
                      quality={100}
                      width={283}
                      height={197}
                      formats={["auto", "webp", "avif"]}
                      loading={'lazy'}
                      alt="Digilink Tablet"
                  />
                  <CustomLocalizedLink
                      label={t('learn_more')}
                      goto={'/products/smart-locks/digilink/'}
                      cls={'btn btn--orange'}/>
                </div>
              </div>
            </div>
          </section>
          <section class="what-trying-to-secure">
            <div className="container">
              <h2 dangerouslySetInnerHTML={{__html:t('secure_headline')}} />
              <div className="cards-container">
                <div className="card">
                  <div className={'card-img-container'}>
                    <h3><Trans>lockers</Trans></h3>
                    <img src={secureLocker} alt="locker room"/>
                  </div>
                  <span className={'card-span'}><Trans>we_recommend</Trans></span>
                  <div className="card-thumbs">
                    <div>
                      <Link className={'thumbnail'} to={'/products'+lockList[0].lock_url}>
                        <img src={lockList[0].lock_thumb}
                             alt={lockList[0].lock_alt}
                             width={lockList[0].lock_width}
                             height={lockList[0].lock_height} />
                        <p><span>Curve<sup>™</sup></span>
                        </p>
                      </Link>
                      <Link className="thumbnail" to={'/products'+lockList[5].lock_url}>
                        <img src={lockList[5].lock_thumb}
                             alt={lockList[5].lock_alt}
                             width={lockList[5].lock_width}
                             height={lockList[5].lock_height} />
                        <p><span>Pivot<sup>™</sup></span>
                        </p>
                      </Link>
                    </div>
                    <div>
                      <Link className="thumbnail" to={'/products'+lockList[7].lock_url}>
                        <img src={lockList[7].lock_thumb}
                             alt={lockList[7].lock_alt}
                             width={lockList[7].lock_width}
                             height={lockList[7].lock_height} />
                        <p><span>Cleo<sup>™</sup></span>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className={'card-img-container'}>
                    <h3><Trans>cabinets</Trans></h3>
                    <img src={secureCabinets} alt="Cabinets"/>
                  </div>
                  <span className={'card-span'}><Trans>we_recommend</Trans></span>
                  <div className="card-thumbs col">
                    <div>
                      <Link to={'/products'+lockList[1].lock_url}>
                        <img src={lockList[1].lock_thumb}
                             alt={lockList[1].lock_alt}
                             width={lockList[1].lock_width}
                             height={lockList[1].lock_height} />
                        <p><span>Aspire<sup>™</sup></span>
                        </p>
                      </Link>
                      <Link className="thumbnail" to={'/products'+lockList[2].lock_url}>
                        <img src={lockList[2].lock_thumb}
                             alt={lockList[2].lock_alt}
                             width={lockList[2].lock_width}
                             height={lockList[2].lock_height} />
                        <p><span>Orbit<sup>™</sup></span>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className={'card-img-container'}>
                    <h3><Trans>pedestals_carts</Trans></h3>
                    <img src={securePedestals} alt="Pedestals/Carts"/>
                  </div>
                  <span className={'card-span'}><Trans>we_recommend</Trans></span>
                  <div className="card-thumbs col">
                    <div>
                      <Link className="thumbnail" to={'/products'+lockList[4].lock_url}>
                        <img src={lockList[4].lock_thumb}
                             alt={lockList[4].lock_alt}
                             width={lockList[4].lock_width}
                             height={lockList[4].lock_height} />
                        <p><span>Versa<sup>™</sup></span>
                        </p>
                      </Link>
                      <Link className="thumbnail" to={'/products'+lockList[2].lock_url}>
                        <img src={lockList[2].lock_thumb}
                             alt={lockList[2].lock_alt}
                             width={lockList[2].lock_width}
                             height={lockList[2].lock_height} />
                        <p><span>Orbit<sup>™</sup></span>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="the-power-partnership">
            <div class="container">
              <h2 dangerouslySetInnerHTML={{__html:t('partnership_headline')}} />
            </div>
            <p dangerouslySetInnerHTML={{__html:t('partnership_copy_locks')}} />
            <div className="text-overlay-right">
              <div className="container">
                <div className="text-overlay-right-content">
                  <h3 dangerouslySetInnerHTML={{__html: t('partnership_subhead')}} />
                  <p dangerouslySetInnerHTML={{__html: t('partnership_subcopy')}} />
                  <BookConsultation />
                </div>
              </div>
            </div>
          </section>
          <section class="unlock-options">
            <div class="container">
              <Banner buttonType={'white'} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter
:
  {
    language: {
      eq: $language
    }
  }
)
  {
    edges
    {
      node
      {
        ns
        data
        language
      }
    }
  }
}
`;
