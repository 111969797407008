import React, {useState} from 'react';
import pulldownArrow from '../dealers/images/filter-menu-down.svg';
import pulldownArrowWhite from '../dealers/images/filter-menu-down-white.svg';


const FilterMenu = ({items, handleCheckedState }) => {
  return (
        <>
          {items.map(({id, type, typeSimple}, index) => {
            return (
                <div>
                  <label htmlFor={type} className={typeSimple}>
                  <input
                      id={type}
                      type="radio"
                      key={id}
                      name={'lock-type'}
                      value={typeSimple}
                      onChange={(e) => handleCheckedState(id)}
                  />
                    {type}
                  </label>
                </div>
            );
          })}
      </>
      )
};

export default FilterMenu;
